<template>
  <img
    src="/resources/assets/images/nimbus-logo-white.svg"
    alt="Nimbus Hosting"
    width="150"
  >
</template>

<script>
export default {
  name: "NimbusLogo",
}
</script>
